<template>
	<div class="invoice-data">
		<div class="header acea-row row-between-wrapper" ref="header">
	      <div class="black" @click="returnChange()">
	        <img src="@assets/images/icon_black.png" alt="" />
	      </div>
	      <p>锂电芯打样参数</p>
	    </div>
	  	<div class="set-container">
			<!-- <div class="set-list acea-row row-between">
				<p>编号：</p>
				<p class="right">
					SXLI202001
				</p>
			</div> -->
			<div class="set-list acea-row row-between">
				<p>电芯材料：</p>
				<p class="right"></p>
			</div>

		</div>
		<div class="set-container">
			<div class="set-list acea-row row-between">
				<p>放电倍率：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>电芯形状：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>厚度：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>宽度：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>高度：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>期望打样周期：</p>
				<p class="right">
					
				</p>
			</div>
		</div>


		<div class="detail-btn acea-row row-between">
	      <div class="right pay bule" :class="firstJum? 'glay':''">
	        <p>返回</p>
	      </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
// import {CellGroup, CellItem} from 'vue-ydui/dist/lib.rem/cell';
export default {
	name:'lithiumParamDianxin',
	// components: {
	//     CellGroup,
	//     CellItem,
	// },
	data(){
		return {

		}
	},
	mounted(){

	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },

	}
}
</script>

<style type="text/css">
.invoice-data { padding-top:50px; }

.invoice-data .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.invoice-data .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.invoice-data .header .black img {
  width: 100%;
  height: 100%;
}
.invoice-data .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>


